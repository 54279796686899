import { Routes, Route, Navigate } from "react-router-dom"

import Home from "../../pages/home/Home"
import Services from "../../pages/services/Services"
import BusinessListing from "../../pages/business-listing/BusinessListing"
import NotAuthRoutes from "./NotAuthRoutes"
import AuthRoutes from "./AuthRoutes"
import EmailVerificationRoutes from "./EmailVerificationRoutes"
import Signup from "../../pages/auth/signup/Signup"
import { loginPath } from "../../assets/constants"
import Login from "../../pages/auth/Login"
import GoogleOAuthVerification from "../../pages/auth/verification/GoogleOAuthVerification"
import EmailTokenLinkVerification from "../../pages/auth/verification/EmailTokenLinkVerification"
import EmailVerification from "../../pages/auth/verification/email/EmailVerification"
import ClientSetupRoutes from "./ClientRoutes/ClientSetupRoutes"
import FreelancerSetupRoutes from "./FreelancerRoutes/FreelancerSetupRoutes"
import ClientProfileForm from "../../pages/auth/setup/client/ProfileForm"
import PrimaryServiceForm from "../../pages/auth/setup/freelancer/PrimaryServiceForm"
import LeadsForm from "../../pages/auth/setup/freelancer/LeadsForm"
import FreelancerProfileForm from "../../pages/auth/setup/freelancer/ProfileForm"
import OtherServicesForm from "../../pages/auth/setup/freelancer/OtherServicesForm"
import ErrorPage from "../ErrorPage/ErrorPage"
import Dashboard from "../../pages/dashboard/Dashboard"
import FreelancerRoutes from "./FreelancerRoutes/FreelancerRoutes"
import ClientRoutes from "./ClientRoutes/ClientRoutes"
import EditProfile from "../../pages/dashboard/edit/Profile/Profile"
import EditPrimaryService from "../../pages/dashboard/edit/PrimaryService"
import EditLeadsLocation from "../../pages/dashboard/edit/LeadsLocation"
import ClientOnlyRoutes from "./ClientRoutes/ClientOnlyRoutes"
import NewRequest from "../../pages/dashboard/request/new/NewRequest"
import WebDesignAndDevelopment from "../../pages/dashboard/request/new/webDesignAndDevelopment/WebDesignAndDevelopment"
import MobileApp from "../../pages/dashboard/request/new/mobileApp/MobileApp"
import DataAndAnalytics from "../../pages/dashboard/request/new/dataAndAnalytics/DataAndAnalytics"
import PersonalDataAndAnalytics from "../../pages/dashboard/request/new/dataAndAnalytics/personal/PersonalDataAndAnalytics"
import BusinessDataAndAnalytics from "../../pages/dashboard/request/new/dataAndAnalytics/business/BusinessDataAndAnalytics"
import SoftwareDevelopment from "../../pages/dashboard/request/new/softwareDevelopment/SoftwareDevelopment"
import FinanceTransformation from "../../pages/dashboard/request/new/financeTransformation/FinanceTransformation"
import FPandARevolution from "../../pages/dashboard/request/new/financeTransformation/FPandARevolution/FPandARevolution"
import FinanceReportingAndAdvancedAnalytics from "../../pages/dashboard/request/new/financeTransformation/FRAA/FRAA"
import Enterprise from "../../pages/dashboard/request/new/financeTransformation/Enterprise/Enterprise"
import SystemAdministration from "../../pages/dashboard/request/new/financeTransformation/SystemAdministration/SystemAdministration"
import DigitalTransformation from "../../pages/dashboard/request/new/financeTransformation/DigitalTransformation/DigitalTransformation"
import OtherFT from "../../pages/dashboard/request/new/financeTransformation/other/OtherFT"
import MachineLearningAndAI from "../../pages/dashboard/request/new/machineLearningAndAI/MachineLearningAndAI"
import RequestConfirmation from "../../pages/dashboard/request/confirmation/RequestConfirmation"
import RequestDetails from "../../pages/dashboard/request/details/RequestDetails"
import AllRequest from "../../pages/dashboard/request/all/AllRequest"
import RelatedFreelancers from "../../pages/dashboard/freelancer/service/RelatedFreelancers"

const AllRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="services/" element={<Services />} />
            <Route path="business-listing/" element={<BusinessListing />} />
            <Route path="auth/">
                <Route element={<NotAuthRoutes />}>
                    <Route path="" element={<Navigate to={loginPath} replace />} />
                    <Route path="login/" element={<Login />} />
                    <Route path="signup/" element={<Signup />} />
                    <Route path="social-accounts/verify/google-oauth2/" element={<GoogleOAuthVerification />} />
                </Route>
                <Route element={<AuthRoutes />}>
                    <Route element={<EmailVerificationRoutes />}>
                        <Route path="verification/:token/email/" element={<EmailTokenLinkVerification />} />
                        <Route path="verification/email/" element={<EmailVerification />} />
                    </Route>
                    <Route path="su/">
                        <Route path="client/" element={<ClientSetupRoutes />}>
                            <Route path="profile/" element={<ClientProfileForm />} />
                        </Route>
                        <Route path="freelancer/" element={<FreelancerSetupRoutes />}>
                            <Route path="primary-service/" element={<PrimaryServiceForm />} />
                            <Route path="leads/" element={<LeadsForm />} />
                            <Route path="profile/" element={<FreelancerProfileForm />} />
                            <Route path="other-services/" element={<OtherServicesForm />} />
                        </Route>
                    </Route>
                </Route>
            </Route>
            <Route path="dashboard/" element={<AuthRoutes />}>
                <Route path="" element={<Dashboard />} />
                <Route path="edit/">
                    <Route path="profile/" element={<EditProfile />} />
                    <Route element={<FreelancerRoutes />}>
                        <Route path="primary-service/" element={<EditPrimaryService />} />
                        <Route path="leads/" element={<EditLeadsLocation />} />
                        <Route path="other-services/" element={<OtherServicesForm />} />
                    </Route>
                </Route>
                <Route path="request/" element={<ClientRoutes />}>
                    <Route path=":id/confirmation/:pid/" element={<RequestConfirmation />} />
                    <Route path=":id/details/" element={<RequestDetails />} />
                    <Route path="all/" element={<AllRequest />} />
                </Route>
                <Route path="freelancer/" >
                    <Route path="service/:id/" element={<RelatedFreelancers />} />
                </Route>
            </Route>
            <Route path="request/new/" element={<ClientOnlyRoutes />}>
                <Route path="" element={<NewRequest />} />
                <Route path="web-design-and-development/" element={<WebDesignAndDevelopment />} />
                <Route path="mobile-app/" element={<MobileApp />} />
                <Route path="data-and-analytics/">
                    <Route path="" element={<DataAndAnalytics />} />
                    <Route path="personal/" element={<PersonalDataAndAnalytics />} />
                    <Route path="business/" element={<BusinessDataAndAnalytics />} />
                </Route>
                <Route path="software-development/" element={<SoftwareDevelopment />} />
                <Route path="finance-transformation/">
                    <Route path="" element={<FinanceTransformation />} />
                    <Route path="fp-and-a-revolution/" element={<FPandARevolution />} />
                    <Route path="financial-reporting-and-advanced-analytics/" element={<FinanceReportingAndAdvancedAnalytics />} />
                    <Route path="enterprise/" element={<Enterprise />} />
                    <Route path="system-administration/" element={<SystemAdministration />} />
                    <Route path="digital-transformation/" element={<DigitalTransformation />} />
                    <Route path="other/" element={<OtherFT />} />
                </Route>
                <Route path="machine-learning-and-ai/" element={<MachineLearningAndAI />} />
            </Route>
            <Route path="*" element={<ErrorPage status={404} />} />
        </Routes>
    )
}
export default AllRoutes
